.navbar-navbar {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1200px;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.navbar-desktop {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.navbar-main {
  gap: var(--dl-space-space-fiveunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.navbar-branding {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.navbar-navlink {
  display: contents;
}
.navbar-finbest {
  width: auto;
  height: var(--dl-size-size-small);
  text-decoration: none;
}
.navbar-links {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  padding-top: 12px;
  border-radius: 6px;
  flex-direction: row;
  padding-bottom: 12px;
  justify-content: center;
}
.navbar-link {
  text-decoration: none;
}
.navbar-link1 {
  text-decoration: none;
}
.navbar-quick-actions {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.navbar-log-in {
  display: none;
}
.navbar-navlink1 {
  display: contents;
}
.navbar-sign-up-btn {
  display: none;
  transition: 0.3s;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  text-decoration: none;
  background-color: #ADE2DF;
}
.navbar-sign-up-btn:hover {
  background-color: rgba(173, 226, 223, 0.5);
}
.navbar-sign-up {
  font-style: normal;
  font-weight: 500;
}
.navbar-hamburger-menu {
  width: 20px;
  height: 12px;
  display: none;
}
.navbar-mobile {
  gap: var(--dl-space-space-fourunits);
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  z-index: 200;
  position: fixed;
  transform: translateX(100%);
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-color: #ffffff;
}
.navbar-top {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.navbar-image {
  width: 150px;
  object-fit: cover;
}
.navbar-icon {
  width: 28px;
  height: 28px;
}
.navbar-links1 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.navbar-link3 {
  text-decoration: none;
}
.navbar-link4 {
  text-decoration: none;
}
.navbar-navlink2 {
  text-decoration: none;
}
.navbar-buttons {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
}
.navbar-navlink3 {
  display: contents;
}
.navbar-btn {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  background-color: #ADE2DF;
}
.navbar-text {
  font-style: normal;
  font-weight: 500;
}
.navbar-navlink4 {
  display: contents;
}
.navbar-btn1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  background-color: #ADE2DF;
}
.navbar-text1 {
  font-style: normal;
  font-weight: 500;
}
.navbar-container1 {
  display: contents;
}
@media(max-width: 991px) {
  .navbar-navbar {
    align-items: center;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .navbar-links {
    display: none;
  }
  .navbar-log-in {
    display: none;
  }
  .navbar-sign-up-btn {
    display: none;
  }
  .navbar-hamburger-menu {
    display: flex;
  }
  .navbar-image {
    width: auto;
    height: var(--dl-size-size-small);
  }
  .navbar-buttons {
    display: none;
  }
}
@media(max-width: 767px) {
  .navbar-navbar {
    align-items: center;
  }
  .navbar-mobile {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-twounits);
  }
}
