.conditions-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.conditions-iframe {
  width: 800px;
  height: 12000px;
  align-self: center;
}
@media(max-width: 767px) {
  .conditions-iframe {
    width: 700px;
  }
}
@media(max-width: 479px) {
  .conditions-iframe {
    width: 520px;
  }
}
