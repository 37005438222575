.social-social {
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  transition: 0.3s;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: flex-start;
  background-color: rgba(236, 233, 234, 0.2);
}
.social-social:hover {
  background-color: rgba(236, 233, 234, 0.6);
}
.social-insider {
  width: 18px;
  height: 18px;
}



@media(max-width: 767px) {
  .social-social {
    padding: 12px;
  }
}
