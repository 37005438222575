.home-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  overflow-x: hidden;
  align-items: center;
  flex-direction: column;
}
.home-header {
  width: 100%;
  height: 120vh;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-hero {
  flex: 1;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1200px;
  align-items: center;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  justify-content: space-between;
}
.home-content {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 0.5;
  display: flex;
  z-index: 20;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
}
.home-title {
  font-size: 64px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 600;
  line-height: 76px;
}
.home-caption {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 27px;
}
.home-hero-buttons {
  gap: var(--dl-space-space-unit);
  display: flex;
  z-index: 15;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-ios-btn {
  gap: var(--dl-space-space-halfunit);
  fill: white;
  color: white;
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  background-color: #000000;
}
.home-ios-btn:hover {
  background-color: rgba(0, 0, 0, 0.75);
}
.home-apple {
  width: 16px;
  height: 20px;
}
.home-caption1 {
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
}
.home-link {
  display: contents;
}
.home-android-btn {
  gap: var(--dl-space-space-halfunit);
  fill: white;
  color: white;
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  text-decoration: none;
  background-color: #000000;
}
.home-android-btn:hover {
  background-color: rgba(0, 0, 0, 0.75);
}
.home-android {
  width: 18px;
  height: 20px;
}
.home-caption2 {
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
}
.home-images {
  gap: var(--dl-space-space-oneandhalfunits);
  right: -210px;
  bottom: -500px;
  margin: var(--dl-space-space-halfunit);
  display: flex;
  overflow: hidden;
  position: absolute;
  transform: rotate(-30deg);
  margin-top: auto;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  flex-direction: row;
  justify-content: center;
}
.home-column {
  gap: var(--dl-space-space-oneandhalfunits);
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-pasted-image {
  width: 240px;
}
.home-column1 {
  gap: var(--dl-space-space-oneandhalfunits);
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-pasted-image1 {
  width: 240px;
}
.home-pasted-image2 {
  width: 240px;
}
.home-column2 {
  gap: var(--dl-space-space-oneandhalfunits);
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-pasted-image3 {
  width: 240px;
}
.home-video {
  color: white;
  width: 100%;
  display: flex;
  z-index: 10;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  background-color: #000000;
}
.home-content01 {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: column;
}
.home-header2 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text {
  width: 640px;
  font-size: 40px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 600;
}
.home-video-container {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 900px;
  align-items: flex-start;
  flex-direction: column;
}
.home-video1 {
  width: 100%;
  cursor: pointer;
  overflow: hidden;
  transition: 0.3s;
  border-color: rgba(0, 0, 0, 0);
  border-width: 2px;
}
.home-video1:hover {
  opacity: .5;
  border-color: #ADE2DF;
  border-width: 2px;
  border-radius: 8px;
}
.home-heading-container {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-heading {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text01 {
  font-size: 24px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 600;
  line-height: 36px;
}
.home-text02 {
  color: rgb(234, 234, 234);
  width: 100%;
  font-family: "Poppins";
  line-height: 24px;
}
.home-explore {
  color: #fff;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: flex-start;
  border-color: #ffffff;
  border-width: 0px;
  flex-direction: column;
  padding-bottom: 4px;
  border-bottom-width: 1px;
}
.home-explore:hover {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.3);
}
.home-sections {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
}
.home-section {
  gap: var(--dl-space-space-sixunits);
  width: 100%;
  display: flex;
  z-index: 10;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  flex-direction: row;
  justify-content: center;
}
.home-image {
  width: 400px;
  height: 540px;
  display: flex;
  position: relative;
  align-items: center;
  border-color: rgba(120, 120, 120, 0.4);
  border-style: hidden;
  border-width: 2px;
  flex-direction: row;
  background-size: contain;
  justify-content: flex-end;
  background-image: url("/static/mobile-app/home-1500h.jpg");
  background-repeat: no-repeat;
  background-position: center;
}
.home-content02 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-text07 {
  font-size: 40px;
  max-width: 535px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 600;
  line-height: 56px;
}
.home-section1 {
  gap: var(--dl-space-space-sixunits);
  width: 100%;
  display: flex;
  z-index: 10;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  flex-direction: row;
  justify-content: center;
}
.home-content03 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-heading1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-text08 {
  font-size: 40px;
  max-width: 535px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 600;
  line-height: 56px;
}
.home-image1 {
  width: 370px;
  height: 750px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  background-size: cover;
  justify-content: flex-end;
  background-image: url("/static/mobile-app/detail-1500h.jpg");
  background-repeat: no-repeat;
  background-position: center;
}
.home-section2 {
  gap: var(--dl-space-space-sixunits);
  width: 100%;
  display: flex;
  z-index: 10;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  flex-direction: row;
  justify-content: center;
}
.home-image2 {
  width: 400px;
  height: 540px;
  display: flex;
  position: relative;
  align-items: center;
  border-color: rgba(120, 120, 120, 0.4);
  border-style: hidden;
  border-width: 2px;
  flex-direction: row;
  background-size: contain;
  justify-content: flex-end;
  background-image: url("/static/mobile-app/visit-1500h.jpg");
  background-repeat: no-repeat;
  background-position: center;
}
.home-content04 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-text09 {
  font-size: 40px;
  max-width: 535px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 600;
  line-height: 56px;
}
.home-section3 {
  gap: var(--dl-space-space-sixunits);
  width: 100%;
  display: none;
  z-index: 10;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  flex-direction: row;
  justify-content: center;
}
.home-content05 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-heading2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-text10 {
  font-size: 40px;
  max-width: 535px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 600;
  line-height: 56px;
}
.home-content06 {
  gap: var(--dl-space-space-threeunits);
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-image3 {
  width: 370px;
  height: 600px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  background-size: cover;
  justify-content: flex-end;
  background-image: url("/external/group%201309.svg");
  background-repeat: no-repeat;
  background-position: center;
}
.home-banner-container {
  width: 100%;
  display: none;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
}
.home-banner {
  width: 100%;
  height: 360px;
  display: flex;
  overflow: hidden;
  max-width: 1200px;
  align-items: center;
  border-radius: 16px;
  flex-direction: column;
  justify-content: center;
  background-color: #375EE0;
}
.home-overlay {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  background-size: cover;
  justify-content: center;
  background-image: url("/mask%20group.svg");
}
.home-text11 {
  color: rgb(193, 255, 252);
  width: 100%;
  font-size: 40px;
  max-width: 490px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 600;
}
.home-book-btn {
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: flex-start;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  background-color: #000000;
}
.home-book-btn:hover {
  background-color: rgba(0, 0, 0, 0.5);
}
.home-text12 {
  color: rgb(255, 255, 255);
  width: 100%;
  max-width: 490px;
  font-style: normal;
  font-weight: 500;
}
.home-pasted-image4 {
  display: none;
}
.home-features {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: none;
  max-width: 1200px;
  align-items: flex-start;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 16px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: flex-start;
}
.home-header3 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-heading3 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text13 {
  width: 505px;
  font-size: 40px;
  max-width: 400px;
  font-style: normal;
  font-weight: 600;
  line-height: 56px;
}
.home-feature-list {
  gap: var(--dl-space-space-fourunits);
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.home-banner1 {
  width: auto;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  border-radius: 15px;
  flex-direction: column;
  justify-content: space-between;
  background-color: #375EE0;
}
.home-text14 {
  color: rgb(193, 255, 252);
  font-size: 40px;
  text-align: center;
  font-family: "Poppins";
  font-weight: 600;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-text17 {
  font-weight: 600;
}
.home-navlink {
  display: contents;
}
.home-book-btn1 {
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: flex-start;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  text-decoration: none;
  background-color: #000000;
}
.home-book-btn1:hover {
  background-color: rgba(0, 0, 0, 0.5);
}
.home-text19 {
  color: rgb(255, 255, 255);
  width: 100%;
  max-width: 490px;
  font-style: normal;
  font-weight: 500;
}
.home-testimonials {
  width: 100%;
  display: flex;
  max-width: 900px;
  align-items: stretch;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
}
.home-logo-container {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: center;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: column;
  background-color: #375EE0;
}
.home-logo {
  width: 25px;
  height: 24px;
}
.home-content07 {
  display: flex;
  position: relative;
  align-items: stretch;
  flex-direction: column;
}
.home-quotes {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-buttons {
  width: 100%;
  display: flex;
  z-index: 100;
  max-width: 500px;
  align-self: center;
  margin-top: -55px;
  align-items: flex-end;
  flex-direction: row;
  justify-content: space-between;
}
.home-left {
  fill: #DBDBDB;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-unit);
  transition: 0.3s;
  align-items: center;
  border-color: #DBDBDB;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
}
.home-left:hover {
  fill: #000;
  border-color: #000;
}
.home-icon {
  width: 19px;
  height: 19px;
}
.home-right {
  fill: #DBDBDB;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-unit);
  transition: 0.3s;
  align-items: center;
  border-color: #DBDBDB;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
}
.home-right:hover {
  fill: #000;
  border-color: #000;
}
.home-icon2 {
  width: 19px;
  height: 19px;
}
.home-container2 {
  display: contents;
}
.home-faq {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 16px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  background-color: #F3F5FA;
}
.home-content08 {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: column;
}
.home-header4 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-tag {
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
  background-color: #D6F1F0;
}
.home-text20 {
  font-style: normal;
  font-weight: 500;
}
.home-heading4 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text23 {
  width: 100%;
  font-size: 40px;
  max-width: 600px;
  font-style: normal;
  text-align: center;
  font-family: "Poppins";
  font-weight: 600;
  line-height: 56px;
}
.home-rows {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-column3 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-title1 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
}
.home-description {
  font-family: "Poppins";
  line-height: 24px;
}
.home-title2 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
}
.home-description1 {
  font-family: "Poppins";
  line-height: 24px;
}
.home-question2 {
  border-bottom-width: 0px;
}
.home-title3 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
}
.home-description2 {
  font-family: "Poppins";
  line-height: 24px;
}
.home-column4 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-question3 {
  border-right-width: 0px;
}
.home-title4 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
}
.home-description3 {
  font-family: "Poppins";
  line-height: 24px;
}
.home-question4 {
  border-right-width: 0px;
}
.home-title5 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
}
.home-description4 {
  font-family: "Poppins";
  line-height: 24px;
}
.home-question5 {
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-title6 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
}
.home-description5 {
  font-family: "Poppins";
  line-height: 24px;
}
.home-get-started {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  background-color: #375EE0;
}
.home-content09 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  max-width: 600px;
  align-items: center;
  flex-direction: column;
}
.home-heading5 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-text28 {
  color: rgb(193, 255, 252);
  font-size: 64px;
  font-style: normal;
  text-align: center;
  font-family: "Poppins";
  font-weight: 600;
}
.home-hero-buttons1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  z-index: 15;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-ios-btn1 {
  gap: var(--dl-space-space-halfunit);
  fill: white;
  color: white;
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  background-color: #000000;
}
.home-ios-btn1:hover {
  background-color: rgba(0, 0, 0, 0.75);
}
.home-apple1 {
  width: 16px;
  height: 20px;
}
.home-caption3 {
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
}
.home-link1 {
  display: contents;
}
.home-android-btn1 {
  gap: var(--dl-space-space-halfunit);
  fill: white;
  color: white;
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  text-decoration: none;
  background-color: #000000;
}
.home-android-btn1:hover {
  background-color: rgba(0, 0, 0, 0.75);
}
.home-android1 {
  width: 18px;
  height: 20px;
}
.home-caption4 {
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
}
@media(max-width: 991px) {
  .home-header {
    height: auto;
  }
  .home-hero {
    flex-direction: column;
  }
  .home-content {
    gap: var(--dl-space-space-unit);
    flex: 0 0 auto;
    padding-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-title {
    font-size: 50px;
    text-align: left;
    line-height: 58px;
  }
  .home-caption {
    font-size: 16px;
  }
  .home-hero-buttons {
    width: 100%;
  }
  .home-ios-btn {
    flex: 1;
  }
  .home-android-btn {
    flex: 1;
  }
  .home-images {
    left: 0px;
    right: -100px;
    bottom: -325px;
    margin: auto;
    display: none;
  }
  .home-pasted-image {
    width: 200px;
  }
  .home-pasted-image1 {
    width: 200px;
  }
  .home-pasted-image2 {
    width: 200px;
  }
  .home-pasted-image3 {
    width: 200px;
  }
  .home-content01 {
    gap: var(--dl-space-space-threeunits);
  }
  .home-section {
    gap: var(--dl-space-space-oneandhalfunits);
    padding-top: var(--dl-space-space-fourunits);
    flex-direction: column-reverse;
  }
  .home-content02 {
    gap: var(--dl-space-space-unit);
  }
  .home-section1 {
    padding-top: var(--dl-space-space-fourunits);
    border-color: #DBDBDB;
    flex-direction: column;
    border-top-width: 1px;
  }
  .home-content03 {
    gap: var(--dl-space-space-unit);
  }
  .home-section2 {
    gap: var(--dl-space-space-oneandhalfunits);
    padding-top: var(--dl-space-space-fourunits);
    flex-direction: column-reverse;
  }
  .home-content04 {
    gap: var(--dl-space-space-unit);
  }
  .home-section3 {
    padding-top: var(--dl-space-space-fourunits);
    border-color: #DBDBDB;
    flex-direction: column;
    border-top-width: 1px;
  }
  .home-content05 {
    gap: var(--dl-space-space-unit);
  }
  .home-content06 {
    gap: var(--dl-space-space-twounits);
  }
  .home-text14 {
    text-align: center;
  }
  .home-get-started {
    padding-top: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-content09 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-heading5 {
    gap: var(--dl-space-space-halfunit);
  }
  .home-text28 {
    font-size: 40px;
  }
}
@media(max-width: 767px) {
  .home-title {
    font-size: 40px;
    line-height: 48px;
  }
  .home-caption {
    font-size: 16px;
    line-height: 24px;
  }
  .home-images {
    bottom: -250px;
  }
  .home-text {
    width: 100%;
    font-size: 32px;
    max-width: 640px;
  }
  .home-video-container {
    gap: var(--dl-space-space-threeunits);
  }
  .home-heading {
    gap: var(--dl-space-space-halfunit);
  }
  .home-text01 {
    font-size: 20px;
    line-height: 30px;
  }
  .home-text02 {
    width: 100%;
    max-width: 600px;
  }
  .home-text07 {
    font-size: 32px;
    line-height: 44px;
  }
  .home-text08 {
    font-size: 32px;
    line-height: 44px;
  }
  .home-text09 {
    font-size: 32px;
    line-height: 44px;
  }
  .home-text10 {
    font-size: 32px;
    line-height: 44px;
  }
  .home-banner {
    height: 520px;
    position: relative;
  }
  .home-overlay {
    gap: var(--dl-space-space-oneandhalfunits);
    flex: 1;
    z-index: 50;
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-threeunits);
    justify-content: flex-start;
    background-image: none;
  }
  .home-text11 {
    font-size: 32px;
  }
  .home-pasted-image4 {
    right: 0px;
    bottom: 0px;
    height: 300px;
    display: flex;
    position: absolute;
    align-self: flex-end;
  }
  .home-features {
    gap: var(--dl-space-space-threeunits);
  }
  .home-header3 {
    gap: var(--dl-space-space-unit);
  }
  .home-text13 {
    font-size: 32px;
    line-height: 44px;
  }
  .home-feature-list {
    gap: var(--dl-space-space-twounits);
    width: 100%;
    flex-direction: column;
  }
  .home-banner1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-testimonials {
    width: 100%;
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-logo-container {
    padding: 12px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-logo {
    width: 16px;
    height: 16px;
  }
  .home-left {
    display: flex;
  }
  .home-right {
    display: flex;
  }
  .home-faq {
    width: 100%;
  }
  .home-content08 {
    gap: var(--dl-space-space-twounits);
  }
  .home-text20 {
    font-size: 14px;
  }
  .home-text23 {
    font-size: 32px;
    line-height: 44px;
  }
  .home-rows {
    flex-direction: column;
  }
  .home-title1 {
    font-size: 16px;
  }
  .home-get-started {
    display: none;
  }
}
@media(max-width: 479px) {
  .home-hero-buttons {
    width: 100%;
    flex-direction: column;
  }
  .home-ios-btn {
    width: 100%;
  }
  .home-android-btn {
    width: 100%;
  }
  .home-images {
    bottom: -300px;
  }
  .home-pasted-image {
    width: 150px;
  }
  .home-pasted-image1 {
    width: 150px;
  }
  .home-pasted-image2 {
    width: 150px;
  }
  .home-pasted-image3 {
    width: 150px;
  }
  .home-image {
    width: 100%;
    max-width: 400px;
  }
  .home-image2 {
    width: 100%;
    max-width: 400px;
  }
  .home-pasted-image4 {
    height: 225px;
  }
  .home-banner1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-buttons {
    margin-top: -40px;
  }
  .home-left {
    padding: var(--dl-space-space-halfunit);
  }
  .home-right {
    padding: var(--dl-space-space-halfunit);
  }
  .home-hero-buttons1 {
    width: 100%;
    flex-direction: column;
  }
  .home-ios-btn1 {
    width: 100%;
  }
  .home-android-btn1 {
    width: 100%;
  }
}
