.heading-heading {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.heading-notification {
  width: 100%;
}
.heading-component {
  text-decoration: none;
}
