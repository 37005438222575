.contact-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.contact-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  justify-content: flex-start;
}
.contact-container2 {
  flex: 0 0 auto;
  width: 50%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.contact-container3 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 50%;
  height: auto;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
.contact-image {
  width: 400px;
  height: 400px;
  align-self: center;
  object-fit: cover;
}
.contact-form {
  width: 100%;
  height: auto;
  display: flex;
}
.contact-container4 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
.contact-text {
  font-size: 30px;
  font-family: "Poppins";
}
.contact-text1 {
  color: #637381;
}
.contact-textinput {
  width: 100%;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-bottom: var(--dl-space-space-unit);
  background-color: #f6f7f8;
}
.contact-textinput1 {
  width: 100%;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-bottom: var(--dl-space-space-unit);
  background-color: #f6f7f8;
}
.contact-textinput2 {
  width: 100%;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-bottom: var(--dl-space-space-unit);
  background-color: #f6f7f8;
}
.contact-textarea {
  width: 100%;
  height: 184px;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-bottom: var(--dl-space-space-unit);
  background-color: #f6f7f8;
}
.contact-book-btn {
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: flex-start;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  background-color: #000000;
}
.contact-book-btn:hover {
  background-color: rgba(0, 0, 0, 0.5);
}
.contact-text2 {
  color: rgb(255, 255, 255);
  width: 100%;
  max-width: 490px;
  font-style: normal;
  font-weight: 500;
}
@media(max-width: 479px) {
  .contact-container2 {
    display: none;
  }
  .contact-container4 {
    width: 100%;
  }
}
